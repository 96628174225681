import { Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'indoPhone' })
export class IndoPhonePipe implements PipeTransform {
	transform(phone: string): string {
		if (phone) {
			if (phone.indexOf('+62') == 0) {
				return phone;
			} else if (phone.startsWith('0')) {
				return '+62' + phone.substring(1, 20);
			}
		}
		return '+62' + phone;
	}
}